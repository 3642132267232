<template>
	<teleport to="#modal_portal">
		<transition name="fade">
			<div class="custom-modal-mask" v-if="modal_status">
				<div class="custom-modal-dialog modal-dialog align-items-center" :class="modal_size">
					<div class="modal-content">
						<div class="modal-header" :class="modal_header" v-if="has_header_slot">
							<slot name="modal_header"></slot>    
						</div>
						<div class="modal-body" :class="modal_body" v-if="has_body_slot">
							<slot name="modal_body"></slot>  
						</div>
						<div class="modal-footer" :class="modal_footer" v-if="has_footer_slot">
							<slot name="modal_footer"></slot>  
						</div>
					</div>
				</div>
			</div>
		</transition>
	</teleport>
</template>

<script>
	export default {
		props: ['modal_status', 'modal_size', 'modal_header', 'modal_body', 'modal_footer'],
		computed: {
            has_header_slot() {
                return !!this.$slots.modal_header
            },
            has_body_slot() {
                return !!this.$slots.modal_body
            },
            has_footer_slot() {
                return !!this.$slots.modal_footer
            }
        },
	}
</script>